import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "Product Description Writing Services",
  whatWeOffer: {
    leftPart: "Ecommerce is booming, and brick and mortar shops are slowly dying out. Every product or service you need can now be found online and delivered directly to your doorstep.",
    rightPart: "However, what makes an ecommerce site stand out are quality product descriptions. Without them, customers will lose trust and move to the competition. In some cases, your competitors will be selling the same thing as you, but their site features in-depth, top-of-the-line descriptions and customers prefer those."
  },
  processText: "Don't lose customers and sales opportunities. Contact us today, and let's get started.",
  testimonials: testimonials
}

const meta = {
  title: "Product Description Writing Services",
  description: "Ecommerce is booming, and brick and mortar shops are slowly dying out. Every product or service you need can now be found online and delivered directly to your doorstep."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/product-descriptions";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)